<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="handleGoPath('/routerClaimManagement/applyClaim')"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="ConstructionMachineryAudit"
      >
      <template slot="operate-left">
        <el-button type="primary"
          size="mini"
          v-if="handleHasPerms('M20-RepeatAudit-ConstructionMachineryAudit-audit')"
          @click="handleAudit()">批量审核</el-button>
      </template>
      <template slot="nodeComputeStatusDesc"
        slot-scope="{item}">
        <div :class="[ 'status', statusClass[item.nodeComputeStatusDesc] ]">{{ item.nodeComputeStatusDesc }}</div>
      </template>

      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          type="text"
          @click="handleDropdownClick('details', item)"
          size="small">查看详情</el-button>
        <el-button type="text"
          v-if="handleHasPerms('M20-RepeatAudit-ConstructionMachineryAudit-audit')" :disabled="!((item.approverIds && item.approverIds.split(',').some(val=> val == $store.state.userInfo.MJUserId)) && item.nodeComputeStatus == 2)"
          @click="handleAudit(item)"
          size="small">审核</el-button>
      </template>
    </GeneralTable>
    <auditDialog v-model="isAuditDialog"
      :auditReason="auditReason"
      @submit="handleSubmitAudit" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import auditDialog from "@/views/policyManagement/policyAudit/components/auditDialog.vue";
import { policyProcessPage, policyProcessApproval, policyProcessExport } from "@/api/policy";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
   name: 'ConstructionMachineryAudit',
  data() {
    return {
      option: {
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: hasPerms('M20-RepeatAudit-ConstructionMachineryAudit-export'),
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [{
          label: '审批编号',
          prop: 'processNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入审批编号",
        },
        {
          label: '发起人',
          prop: 'starterName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入发起人",
        }, {
          label: '发起时间',
          prop: 'createTime',
          formType: 'daterange',
          clearable: true,
          format: 'yyyy.MM.dd',
          prop: ['startTimeBegin', 'startTimeEnd'],
        }, {
          label: '审核类型',
          prop: 'processType',
          placeholder: "请选择",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "询价单" },
              { value: "2", text: "保单" },
            ],
        },
        {
          label: '审核状态',
          prop: 'processStatus',
          placeholder: "请选择",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "5", text: "系统通过" },
              { value: "3", text: "人工通过" },
              { value: "2", text: "审核中" },
              { value: "1", text: "审核拒绝" },
              { value: "4", text: "已撤回" },
            ],
        },
      //   {
      //     label: '审核时间',
      //     prop: 'nodeApproveTime',
      //     formType: 'daterange',
      //     clearable: true,
      //     format: 'yyyy.MM.dd',
      //     prop: ['nodeApproveTimeBegin', 'nodeApproveTimeEnd'],
      //   }
        ]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "审批编号",
          prop: "processNo",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "发起人",
          prop: "starterName",
          align: 'center',
          width: 220,
          isShow: true,
        },
        {
          label: "发起时间",
          prop: "startTime",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "审核类型",
          prop: "processTypeDesc",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "审核摘要",
          prop: "startRemark",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "审核状态",
          prop: "nodeComputeStatusDesc",
          align: 'center',
          width: 100,
          isShow: true,
          isSlot: true,
        },
      //   {
      //     label: "审核时间",
      //     prop: "approveTime",
      //     align: 'center',
      //     width: 200,
      //     isShow: true,
      //   },
        {
          label: "当前环节",
          prop: "nodeName",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 300,
          fixed: 'right',
          isSlot: true,
        },
      ],
      searchData: {
      },
      oldSearchData: {
      },
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      statusClass: {
        '审核通过': 'status1',
        '审核中': 'status2',
        '待审核': 'status2',
        '审核驳回': 'status4',
        '已结案': 'status3',
        '已撤回': 'status3'
      },
      approvalType: 5,
      isAuditDialog: false,
      AuditId: '',
      auditReason: {},
      isNegotiation: false,
      editData: {}
    };
  },
  components: { GeneralTable, auditDialog },
  created() {
  },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      let currentData = {};
      // if (isReset == 'fuzzy' || (isReset == 'refresh' && (this.oldSearchData.caseNo && this.oldSearchData.caseNo === this.oldSearchData.policyNo && this.oldSearchData.processNo === this.oldSearchData.handlerStatusDesc))) {
      //   let currentData2 = {
      //     caseFollower: v.keyword,
      //     caseNo: v.keyword,
      //     handlerStatusDesc: v.keyword,
      //     policyNo: v.keyword,
      //     processNo: v.keyword,
      //     starter: v.keyword,
      //     processType: this.approvalType
      //   };
      //   if (isReset == 'refresh') {
      //     currentData2 = JSON.parse(JSON.stringify(this.oldSearchData));
      //   }
      //   this.oldSearchData = JSON.parse(JSON.stringify(currentData2));
      //   console.log(currentData2);
      //   const { code, data } = await insClaimsProcessFastPage(currentData2);
      //   if (code === 200 && data.records && data.records.length) {
      //     this.claimRecordList = data.records;
      //     this.totalNum = data.total;
      //   } else {
      //     this.claimRecordList = [];
      //   }
      //   return false;
      // } else 
      if (isReset == 'reset') {
      } else if (isReset == 'refresh') {
        currentData = JSON.parse(JSON.stringify(this.oldSearchData));
      } else {
      //   this.oldSearchData = JSON.parse(JSON.stringify(this.searchData));
        currentData = JSON.parse(JSON.stringify(this.searchData));
      }
      if (v.startTimeBegin) {
        v.startTimeBegin += ' 00:00:00';
        v.startTimeEnd += ' 23:59:59';
      }

      this.oldSearchData = JSON.parse(JSON.stringify({ ...v, ...currentData, current: pageNum, size: pageSize, fastLike: v.keyword, category: 1 }));
      const { code, data } = await policyProcessPage({ ...v, ...currentData, current: pageNum, size: pageSize, fastLike: v.keyword, category: 1 });
      if (code === 200 && data.records && data.records.length) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      } else {
        this.totalNum = 0;
        this.claimRecordList = [];
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm('确认删除该数据?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id);
          insClaimsProcessRemove(ids).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的数据！');
      }
    },
    exportData(e) {
      policyProcessExport({...this.oldSearchData, ids:e.map(val => val.id)});
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDataPermissions(key, perms) {
      return dataPermissions(key, perms);
    },
    handleDropdownClick(e, item) {
      if (item.processType == 1) {
         this.$router.push({
         path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/enquiryDetails/` + item.bussId,
         });
      } else if (item.processType == 2) {
         this.$router.push({
         path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/policyDetails/` + item.bussId,
         });
      }
    },
    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (decimal == 2) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      } else {
        // 只能输入4个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');
      }

      //如果有小数点，不能为类似 1.10的金额  
      // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      // //如果有小数点，不能为类似 0.00的金额 
      // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
      if (e.indexOf(".") <= 0 && e != "") {
        tiem[key] = parseFloat(e);
      }
      if (e > max) {
        const aa = e + '';
        tiem[key] = parseFloat(aa.slice(0, e.length - 1));
      }
    },
    handleGoPath(path) {
      this.$router.push({ path });
    },
    handleAudit(e) {
      if (e) {
         this.AuditId = e.id;
      }else if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
         let idList = this.$refs.GeneralTable.tableSelectionList.filter(val=> val.approverIds.split(',').some(val2=> val2 == this.$store.state.userInfo.MJUserId) && val.nodeComputeStatus == 2);
         if (idList && idList.length) {
            this.AuditId = idList.map((val) => val.id).toString();
         }else {
            this.$message.error('请选择可以审核的单')
            return false;
         }
      }else {
         this.$message.error('请选择需要审核的单')
         return false;
      }
      this.auditReason = e;
      this.isAuditDialog = true;
    },
    handleNegotiation(e) {
      this.editData = e;
      this.isNegotiation = true;
    },
    handleSubmitAudit(e) {
      policyProcessApproval({
        ...e,
        ids: this.AuditId
      }).then(res => {
        this.isAuditDialog = false;
        this.changeList();
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
    .status {
      position: relative;
      padding-left: 18px;
      font-size: 14px;
    }
    .status::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .status1 {
      color: #00bc0d;
    }
    .status1::after {
      background-color: #00bc0d;
    }
    .status2 {
      color: #0080ff;
    }
    .status2::after {
      background-color: #0080ff;
    }
    .status3 {
      color: #cccccc;
    }
    .status3::after {
      background-color: #cccccc;
    }
    .status4 {
      color: #c94242;
    }
    .status4::after {
      background-color: #c94242;
    }
  }
}
.reset-password {
  display: flex;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 12px;
}
::v-deep .el-dropdown,
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>